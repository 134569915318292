.services-container {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 1.3rem;
}

.services-cards {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 2rem;
}

@media screen and (min-width: 1125px) {
  .services-cards {
    padding: 0;
  }
}
