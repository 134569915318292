.portfolio {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.portfolio .list {
  margin: 10px;
  padding: 0;
  list-style: none;
  display: flex;
  width: 85%;
  justify-content: space-around;
  align-items: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem;
  justify-content: center;
  align-items: center;
}

.column {
  display: flex;
  flex: 25%;
  max-width: 25%;
  width: 100%;
  margin-right: 2rem;
  margin-top: 2rem;
  align-self: normal !important;
  border-radius: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: white;
  transition: all 0.6s ease;
  position: relative;
  align-items: flex-end;
  justify-content: center;
   background-color: white;
}

.column img {
  border-radius: 5px;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
}

.overlay {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.623);
  padding: 1rem 1.5rem;
  display: flex;
  transition: all 0.5s ease;
}

.overlay .left {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overlay h3 {
  font-weight: 700;
  font-size: 0.9rem;
}

.overlay p {
  font-size: 0.7rem;
  color: rgb(245, 241, 241);
}

.overlay .right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right .icon {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.89);
}

.right .icon:hover {
  color: white;
  transform: scale(1);
}

/* MEDIA */
@media screen and (min-width: 500px) {
  .overlay h3 {
    font-size: 1.2rem;
  }

  .overlay p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 700px) {
  .column {
    flex: 100%;
    max-width: 100%;
    margin: 0;
    margin-top: 1rem;
  }
}

@media screen and (min-width: 701px) {
  .column {
    flex: 46%;
    max-width: 46%;
    transition: all 2s ease;
    overflow: hidden;
  }

  .column img {
    z-index: 0;
    opacity: 1.95;
  }

  .column:hover img {
    opacity: 0.5;
    z-index: 0;
  }

  .overlay {
    position: absolute;
    padding: 1rem 1.5rem;
    transition: all 0.5s ease;
    bottom: -300px;
  }

  .column:hover .overlay {
    bottom: 0px;
  }

  .portfolio .list {
    width: 60%;
    justify-content: space-evenly;
  }
}
