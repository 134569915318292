.none {
  display: none !important;
  opacity: 0;
}

.testimonial-section {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.testimonials {
  padding: 1rem 2rem;
  display: flex;
  min-height: 350px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.testimonial-card {
  width: 100%;
  background: #002744;
  padding: 1rem 2rem;
  border-radius: 2px;
  box-shadow: 1px 3px 5px gray;
  position: relative;
  transition: all 2s ease;
  opacity: 1;
}

.block {
  display: block;
}

.testimonial-card .svg-image-container {
  border: none;
  width: 70px;
  background-color: white;
  padding: 0.2rem;
  padding-right: 0.5rem;
  position: absolute;
  top: -30px;
  left: 27px;
  border-radius: 20px;
}

.svg-image-container img {
  width: 100%;
}

.testimonial-card > .buttons-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  align-items: center;
}

.svg {
  transition: all 0.3s ease;
  fill: white;
  cursor: pointer;
}

.svg:hover {
  fill: #1dca65;
}

.testimonial-card .source-icon {
  color: #24b417;
  font-size: 2rem;
  font-weight: 900;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.testimonial-card .details {
  text-align: left;
  padding: 2rem 0;
}

.details .text {
  font-size: medium;
  font-weight: 500;
  color: white;
  text-align: justify;
}

.details .country {
  color: #1dca65;
  margin-left: 0.5rem;
}

.source {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.2rem;
}

.details .name {
  font-size: medium;
  color: white;
  text-align: left;
}

.source .link {
  text-decoration: none;
  color: white;
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s ease;
}

.source .link:hover {
  color: #1dca65;
}

.details .icon {
  margin-left: 0.3rem;
}

@media screen and (min-width: 900px) {
  .testimonial-card {
    width: 50rem;
  }

  .details .text {
    font-weight: 400;
    font-size: large;
  }
}
